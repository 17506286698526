import React from 'react';
import Image from 'next/future/image';

import clsx from 'clsx';
import { get } from 'lodash';

import { MenuItem } from '@/types/Menu';
import LinkTo from '@/components/LinkTo';

interface Props {
  item: MenuItem;
  toggleOpen: () => void;
}

const MenuList: React.FC<Props> = ({ item, toggleOpen }) => {
  return (
    <ul className="flex-1 flex space-x-4 py-10 px-8 justify-center">
      {item.children?.map((item, idx) => (
        <li
          key={idx}
          className="space-y-6 xl:min-w-[7vw] 2xl:flex-1 2xl:max-w-[20vw]"
        >
          <div className="space-y-3">
            {item.href ? (
              <LinkTo
                href={item.href}
                aria-label={item.name}
                className="font-bold uppercase tracking-uyn hover:underline"
              >
                {item.name}
              </LinkTo>
            ) : (
              <h3 className="font-bold uppercase tracking-uyn hover:underline">
                {item.name}
              </h3>
            )}

            {item.image && item.href && (
              <LinkTo
                href={item.href}
                aria-label={item.name}
                onClick={toggleOpen}
                className="block"
              >
                <Image
                  src={item.image}
                  alt={get(item, 'name', '')}
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="mb-2 h-28 w-auto 2xl:w-full 2xl:h-auto"
                />
              </LinkTo>
            )}
          </div>

          <ul className="space-y-3">
            {item.children?.map((item, idx) => (
              <li key={idx}>
                {item.image && item.href && (
                  <LinkTo
                    href={item.href}
                    aria-label={item.name}
                    onClick={toggleOpen}
                    className="block"
                  >
                    <Image
                      src={item.image}
                      alt={get(item, 'name', '')}
                      width={0}
                      height={0}
                      sizes="100vw"
                      className="mb-2 h-28 w-auto 2xl:w-full 2xl:h-auto"
                    />
                  </LinkTo>
                )}

                {item.href && (
                  <LinkTo
                    href={item.href}
                    aria-label={item.name}
                    onClick={toggleOpen}
                    className={clsx('text-sm tracking-wide', {
                      'font-bold uppercase': item.highlight
                    })}
                  >
                    <div className="hover:underline">{item.name}</div>
                  </LinkTo>
                )}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default MenuList;
